import { computed, onMounted, watch } from 'vue'

import {
  type Product as AttraqtProduct,
  getAllRecommendations,
  getRecommendationById,
} from '@backmarket/http-api/src/api-specs-search-reco/recommendations/recommendation'
import { useAuthStore } from '@backmarket/nuxt-layer-oauth/useAuthStore'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

import {
  getUserRecommendationId,
  setRecommendationDebugExtension,
} from '../helpers/recommendationHelper'
import type { Product } from '../models/product'

function getWarrantyDuration(warranty: string): number | undefined {
  try {
    return parseInt(warranty, 10)
  } catch (err) {
    return undefined
  }
}

function toProduct(product: AttraqtProduct): Product {
  const specifications: Record<string, { name: string; value?: string }[]> =
    product.specifications.reduce(
      (acc, { field, values }) => ({
        ...acc,
        [field]: values.map((value) => ({
          name: value.label,
        })),
      }),
      {},
    )

  return {
    id: product.uuid,
    title: product.displayTitle || product.title,
    description: product.subTitles?.join(' - '),
    image: product.images[0],
    grade: product.listing.grade,
    productPageLink: product.link,
    price: product.listing.price,
    priceNew: product.referencePrice,
    reviewRating: product.reviewRating,
    brand: product.brand,
    category: product.category,
    listingId: product.listing.id,
    sellerId: product.listing.sellerId,
    model: product.displayTitle ?? 'unknown',
    specifications,
    availableStock: product.listing.stockWarning,
    warrantyDuration: getWarrantyDuration(product.listing.warranty),
  }
}

function useIdentity() {
  const { userId } = useAuthStore()
  if (userId)
    return {
      identityRepository: 'customerId',
      identity: userId,
    }
  const attraqtSessionId = getUserRecommendationId()
  if (attraqtSessionId)
    return {
      identityRepository: 'sessionId',
      identity: attraqtSessionId,
    }

  return {
    identityRepository: undefined,
    identity: undefined,
  }
}

export function useRecommendedProducts({
  limit = 4,
  scope,
  scopeId,
  category,
  widgetId,
  personalisation,
}: {
  limit?: number
  scope?: string
  scopeId?: string
  category?: string
  widgetId?: string
  personalisation?: boolean
}) {
  const { identity, identityRepository } = personalisation
    ? useIdentity()
    : {
        identity: undefined,
        identityRepository: undefined,
      }

  const { data, execute, pending, error } = widgetId
    ? useHttpFetch(getRecommendationById, {
        queryParams: {
          limit,
          identity,
          identityRepository,
        },
        pathParams: { widgetId },
        immediate: false,
      })
    : useHttpFetch(getAllRecommendations, {
        queryParams: {
          limit,
          scope,
          scopeId,
          category,
          identityRepository,
          identity,
        },
        immediate: false,
      })

  onMounted(() => {
    void execute()
  })

  watch(data, () => {
    const payload = data.value
    if (payload) {
      const result = Array.isArray(payload) ? payload[0] : payload
      setRecommendationDebugExtension(result.recommendationRequestId)
    }
  })

  const products = computed(() => {
    if (!data.value) return []

    const results = Array.isArray(data.value) ? data.value[0] : data.value

    return results.products.map((product) => toProduct(product))
  })

  return { pending, products, error }
}

export function useBundleProduct(mainProduct: Product) {
  const { execute, pending, data } = useHttpFetch(getAllRecommendations, {
    queryParams: {
      limit: 1,
      scope: 'listingBundle',
      scopeId: mainProduct.listingId,
      category: 'bundles',
    },
    lazy: true,
    default: () => ({ products: [], widgetId: '' }),
    transform: (response) => response?.[0] ?? null,
    immediate: false,
  })

  const recommendedProduct = computed<Product | null>(() =>
    data.value.products.length > 0 ? toProduct(data.value.products[0]) : null,
  )

  const totalPrice = computed(() =>
    recommendedProduct.value
      ? parseFloat(mainProduct.price.amount) +
        parseFloat(recommendedProduct.value.price.amount)
      : null,
  )

  const widgetId = computed(() => data.value.widgetId)

  onMounted(() => {
    void execute()
  })

  return {
    pending,
    recommendedProduct,
    totalPrice,
    widgetId,
  }
}
