<template>
  <div>
    <RevSkeleton
      v-if="withTitle"
      class="mb-32"
      height="32px"
      shape="rectangle"
      width="190px"
    />

    <RevSkeleton
      :aria-label="i18n(translations.loading)"
      height="370px"
      shape="rectangle"
      width="100%"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevSkeleton } from '@ds/components/Skeleton'

import translations from './ProductCardCarousel.translations'

const i18n = useI18n()

withDefaults(defineProps<{ withTitle: boolean }>(), { withTitle: true })
</script>
